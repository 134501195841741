<template>
	<button @click="openBookingEngine({ ...$props })">
		<slot />
	</button>
</template>

<script setup>
import BookingEngine from '@becurious/mews';

const { locale } = useI18n();
const config = useRuntimeConfig();
const bookingEngine = useState('bookingEngine', () => {});

defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	packageid: { type: String, default: '' },
	promo: { type: String, default: '' },
	roomid: { type: String, default: '' },
});

useHead({
	script: [
		{
			key: 'mews-distributor',
			src: 'https://api.mews.com/distributor/distributor.min.js',
		},
	],
});

const openBookingEngine = (options) => {
	if (!bookingEngine.value) {
		return;
	}

	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	bookingEngine.value.visitUrl(options);
};

onMounted(() => {
	if (!bookingEngine.value) {
		bookingEngine.value = new BookingEngine({
			hotelID: '31c505f5-330b-44f2-a123-ab8a00d6af82',
			gtmID: config.public.googleTagManagerId,
			primaryColor: '#ffca5e',
		});
	}
});
</script>

<style lang="scss" scoped>
button {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
